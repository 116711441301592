export default {
  general: {
    username: 'Korisničko ime',
    password: 'Lozinka',
    newpass: 'Nova lozinka',
    currentpass: 'Trenutna lozinka',
    c_password: 'Potvrdi lozinku',
    c_newpass: 'Potvrdi novu lozinku',
    email: 'Email adresa',
    language: 'Jezik',
    subject: 'Naslov',
    message: 'Poruka',
    filtername: 'Naziv filtera',
    placeholders: {
      username: 'Unijeti korisničko ime',
      password: 'Unijeti lozinku',
      newpass: 'Unijeti novu lozinku',
      currentpass: 'Unijeti trenutnu lozinku',
      c_password: 'Potvrdi lozinku',
      c_newpass: 'Potvrdi novu lozinku',
      email: 'Unijeti email adresu',
      subject: 'Unijeti naslov',
      message: 'Napisati poruku',
      filtername: 'Unijeti naziv filtera'
    },
    errors: {
      language: {
        load: 'Greška u promjeni jezika.'
      }
    },
    here: 'Ovdje',
    ok: 'Ok',
    cancel: 'Odustani',
    on: 'Da',
    off: 'Ne',
    min: 'Minimum',
    max: 'Maksimum',
    years: '{n} godina | {n} godine',
    days: '{n} dan | {n} dana',
    type_for_more: 'Ukucati da bi vidjeli više...'
  },
  notifications: {
    disabled: '{notifications} su blokirana. Ako ih niste blokirali, razmislite o upotrebi drugog pretraživača.',
    notifications: 'Obavještenja',
    title: 'Podešavanje obavještenja',
    form: {
      email: 'Email obavještenja',
      email_frequency: 'Učestalost email obavještenja',
      browser: 'Podešavanje pregledača',
      browser_frequency: 'Učestalost obavještenja o pregledaču',
      frequency: 'odmah | na svakih {n} sat | na svakih {n} sata/i',
      help: {
        email: 'Obavještenja o igračima dobijaćete preko email adrese.',
        browser: 'Obavještenja o igračima dobijaćete direktno u vašem pregledaču.',
        frequency: 'Preporučeno podešavanje je \'odmah\' da ne bi propustili nekog igrača.',
        blocked: 'Blokirali ste obavještenja ili ih ovaj pregledač ne podržava.'
      }
    }
  },
  header: {
    menu: {
      home: 'Početna',
      account: 'Nalog',
      signin: 'Prijava',
      register: 'Registracija',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Kontakt',
      logout: 'Odjava'
    }
  },
  footer: {
    menu: {
      privacy: 'Privatnost',
      terms: 'Uslovi'
    }
  },
  login: {
    title: 'Prijava',
    signin: 'Prijava',
    register: 'Registracija',
    forgot: 'Zaboravljena lozinka?',
    form: {
      submit: 'Prijava',
      remember: 'Zapamti me'
    },
    info: {
      what: 'Šta Mercattrick radi?',
      purpose: 'Svrha Mercattrick-a je:',
      purpose1: 'pomoći Hattrick <strong>menadžerima</strong> da <strong>prodaju</strong> igrače uz bolju promociju i na taj način izvuku maksimum;',
      purpose2: 'pomoći Hattrick <strong>menadžerima</strong> koji su <strong>zainteresovani za kupovinu</strong> određenog tipa igrača da dobiju obavještenje kada se takav igrač pojavi na transfer listi;',
      how: 'Kako radi?',
      buyers: 'Kupci',
      step1: 'prijavite se i unesite minimum vještina (filter) za koji želite da Vas obavijestimo kada se pojave u prodaji.',
      sellers: 'Prodavci',
      step2: 'na HT-u stavite igrača na prodaju, zatim se prijavite i "promovišite" te igrače.',
      promoting: 'Promovisanje',
      step3: 'igrača znači da svi "kupci" zainteresovani za taj tip igrača dobijaju obavještenje da je takav igrač na prodaju.'
    }
  },
  register: {
    title: 'Registracija',
    form: {
      registered: 'Vaš nalog je napravljen. Kliknite {here} za nastavak.',
      submit: 'Registruj',
      agreement: 'Pritiskom na dugme @:register.form.submit slažete se sa {terms} i {privacy} i na povremeno primanje poruka vezanih za nalog.',
      tos: 'uslovi koriščenja',
      privacy: 'izjava o privatnosti',
      help: {
        username: 'Ovo nije Vaše HT korisničko ime.',
        password: 'Unijeti minimum 6 karaktera. Lozinka razlikuje velika i mala slova.',
        email: 'Iskoristićemo ovu email adresu za obavještenja o novim igračima.'
      }
    }
  },
  recover: {
    title: 'Oporavak lozinke',
    form: {
      recovered: 'Na Vašu email adresu poslat je link za reset lozinke',
      submit: 'Oporavi',
      help: {
        email: 'Ovo mora biti email adresa povezana sa Vašim nalogom.'
      }
    }
  },
  reset: {
    title: 'Resetovanje lozinke',
    form: {
      submit: 'Resetuj',
      help: {
        email: 'Ovo mora biti email adresa povezana sa Vašim nalogom.',
        password: 'Unijeti minimum 6 karaktera. Lozinka razlikuje velika i mala slova.'
      }
    }
  },
  contact: {
    title: 'Kontaktirajte nas',
    form: {
      success: 'Poruka je uspješno poslata.',
      submit: 'Pošalji',
      help: {
        email: 'Iskoristićemo ovu adresu samo da bi Vam poslali odgovor.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Sinhronizacija korisničkih podataka...',
      fail: 'Sinhronizacija nije uspjela.',
      retry: 'Pokušajte ponovo.',
      authorize: 'Morate autorizovati ovaj CHPP proizvod. Kliknite {here} da otvorite Hattrick.',
      authorizing: 'Pokretanje autorizacije. Bićete preusmjereni na <strong>hattrick.org</strong> uskoro...',
      finalize: 'Završavanje autorizacije...'
    },
    system: {
      syncing: 'Sinhronizacija sistemskih detalja...',
      fail: 'Sistemska sinhronizacija nije uspjela. {retry} ili {skip}!',
      retry: 'Pokušajte ponovo',
      skip: 'Odustani'
    }
  },
  account: {
    account: {
      title: 'Podešavanje naloga',
      form: {
        success: 'Nalog je uspješno ažuriran.',
        submit: 'Sačuvaj',
        help: {
          username: 'Ovo nije Vaše HT korisničko ime.',
          email: 'Iskoristićemo ovu email adresu za obavještenja o novim igračima.'
        }
      }
    },
    login: {
      title: 'Podešavanje prijave',
      form: {
        success: 'Lozinka je uspješno ažurirana.',
        submit: 'Sačuvaj',
        help: {
          passwords: 'Ukucajte trenutnu lozinku, novu lozinku i potvrdite novu lozinku.'
        }
      }
    },
    privacy: {
      title: 'Podešavanje privatnosti',
      form: {
        submit: 'Izbrišite nalog',
        delete: 'Izbriši',
        help: {
          warning: 'Ova akcija je neopoziva (trajna). Svi vaši podaci biće trajno izbrisani.',
          confirmation: 'Potvrdite da želite trajno izbrisati nalog.'
        }
      }
    }
  },
  filter: {
    title: 'Klikni da dodaš/ukloniš filter',
    form: {
      submit: 'Sačuvaj',
      set: 'Postavi',
      remove: 'Izbriši',
      help: {
        name: 'Dajte filteru ime da bi ga lakše identifikovali.'
      }
    },
    tooltip: {
      save: 'Morate postaviti makar jedan kriterijum filtriranja!'
    },
    item: {
      tooltip: 'Kliknite \'@:filter.item.show_players\' da prikažete listu.',
      show_players: 'Prikaži igrače',
      hide_players: 'Sakrij igrače',
      players: 'pronađen igrač | pronađeni igrači',
      form: {
        processing: 'obrada',
        fetch: 'Pogledaj HT igrača',
        edit: 'Izmijeni',
        delete: 'Izbriši',
        help: {
          confirm: 'Da li ste sigurni da želite obrisati filter?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Moji transferi',
      loading: 'Učitavanje igrača...',
      none: 'Nemate igrača koji se prodaju, u ovom momentu.',
      promoted: 'promovisan',
      late: 'prekasno za promovisanje',
      form: {
        reload: 'Učitaj ponovo',
        promote: 'Promoviši'
      },
      tooltip: 'Promovišite svoje igrače da ih drugi vide.'
    },
    bookmarks: {
      title: 'Obilježivači (bookmarks)',
      loading: 'Učitavanje igrača...',
      none: 'Nemate obilježenih igrača, u ovom momentu.',
      form: {
        reload: 'Učitaj ponovo',
        unbookmark: 'Ukloni oznaku',
        help: {
          confirm: 'Da li ste sigurni da želite da uklonite oznaku?'
        }
      },
      tooltip: 'Primićete obavještenje o ovim igračima kada budu blizu isteka roka.'
    },
    filters: {
      title: 'Moji filteri',
      loading: 'Učitavanje filtera...',
      none: 'Nemate nijedan filter. Trebali bi napraviti neke.',
      form: {
        reload: 'Učitaj ponovo',
        add: 'Dodaj filter',
        bookmark: 'Obilježi',
        hide: 'Sakrij',
        help: {
          confirm: 'Da li ste sigurni da želite sakriti ovog igrača?'
        }
      },
      tooltip: 'Obilježite zanimljive igrače sa neke od ovih lista.'
    }
  },

  player: {
    attributes: {
      name: 'Ime',
      age: 'Starost',
      form: 'Forma',
      experience: 'Iskustvo',
      leadership: 'Vođstvo',
      stamina_skill: 'Kondicija',
      keeper_skill: 'Na golu',
      playmaker_skill: 'Plejmejking',
      scorer_skill: 'Napad',
      passing_skill: 'Dodavanje',
      winger_skill: 'Krilo',
      defender_skill: 'Odbrana',
      set_pieces_skill: 'Prekidi',
      agreeability: 'Ličnost',
      aggressiveness: 'Temperament',
      honesty: 'Poštenje',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Plata',
      price: 'Cijena',
      asking_price: 'Početna cijena',
      highest_bid: 'Najveća ponuda',
      native_country: 'Nacionalnost',
      native_country_id: 'Nacionalnost',
      NT_player: 'Igrač reprezentacije',
      specialties: 'Specijalnosti',
      specialty: 'Specijalnost'
    },
    redcard: 'Crveni kartoni',
    yellowcard: 'Žuti kartoni',
    bruised: 'Ugruvan',
    injured: 'Povređen',
    injury: '{n} nedjelju | {n} nedjelja',
    fullage: '{years} godina i {days} dana',
    deadline: 'Rok',
    htms: 'HTMS/HTMS28',
    more: 'Prikaži više',
    less: 'Prikaži manje',
    levels: {
      skill: [
        'nepostojeći',
        'katastrofalan',
        'očajan',
        'jadan',
        'slab',
        'nedovoljan',
        'prosečan',
        'dobar',
        'odličan',
        'silan',
        'izuzetan',
        'briljantan',
        'fantastičan',
        'svetska klasa',
        'natprirodan',
        'veličanstven',
        'legendaran',
        'epski',
        'magičan',
        'savršen',
        'božanski',
        'božanski (+1)',
        'božanski (+2)',
        'božanski (+3)',
        'božanski (+4)',
        'božanski (+5)'
      ],
      agreeability: [
        'nezgodan momak',
        'kontroverzna osoba',
        'prijatan dečko',
        'simpatičan tip',
        'popularan lik',
        'voljeni član tima'
      ],
      aggressiveness: [
        'spokojan',
        'miran',
        'uravnotežen',
        'temperamentan',
        'prgav',
        'nestabilan'
      ],
      honesty: [
        'ozloglašen',
        'nečastan',
        'častan',
        'pravedan',
        'čestit',
        'kao svetac'
      ],
      specialty: [
        'nema',
        'tehničar',
        'brz',
        'snažan',
        'nepredvidljiv',
        'igra glavom',
        'otporan',
        '',
        'motivator'
      ],
      national_team: [
        'nikad',
        'trenutno nije član',
        'trenutno član'
      ],
      any: 'bilo koja/nema'
    },
    tooltips: {
      highest_bid: 'Najveća ponuda je možda zastarela!'
    }
  },
  countries: {
    1: 'Švedska',
    2: 'Engleska',
    3: 'Njemačka',
    4: 'Italija',
    5: 'Francuska',
    6: 'Meksiko',
    7: 'Argentina',
    8: 'SAD',
    9: 'Norveška',
    10: 'Danska',
    11: 'Finska',
    12: 'Holandija',
    13: 'Okeanija',
    14: 'Kanada',
    15: 'Škotska',
    16: 'Irska',
    17: 'Čile',
    18: 'Kolumbija',
    19: 'Urugvaj',
    20: 'Venecuela',
    21: 'Peru',
    22: 'Brazil',
    23: 'Portugal',
    24: 'Južna Afrika',
    25: 'Japan',
    26: 'Poljska',
    27: 'Indija',
    28: 'Kina',
    29: 'Južna Koreja',
    30: 'Tajland',
    31: 'Turska',
    32: 'Egipat',
    33: 'Austrija',
    34: 'Rusija',
    35: 'Španija',
    36: 'Ruminija',
    37: 'Island',
    38: 'Belgija',
    39: 'Malezija',
    40: 'Švajcarska',
    41: 'Singapur',
    42: 'Hrvatska',
    43: 'Srbija',
    44: 'Mađarska',
    45: 'Grčka',
    46: 'Češka Republika',
    47: 'Estonija',
    48: 'Letonija',
    49: 'Indonezija',
    50: 'Filipini',
    51: 'Izrael',
    52: 'Kineski Tajpeh',
    53: 'Hong Kong',
    55: 'Bugarska',
    56: 'Vels',
    57: 'Slovenija',
    61: 'Litvanija',
    62: 'Ukrajina',
    63: 'Bosna i Hercegovina',
    64: 'Pakistan',
    65: 'Vijetnam',
    66: 'Slovačka',
    67: 'Paragvaj',
    68: 'Ekvador',
    69: 'Bolivija',
    70: 'Nigerjia',
    71: 'Farska Ostrva',
    72: 'Moroko',
    75: 'Saudijska Arabija',
    76: 'Tunis',
    77: 'Kostarika',
    78: 'UAE',
    79: 'Luksemburg',
    80: 'Iran',
    82: 'Kipar',
    83: 'Dominikanska Republika',
    86: 'Senegal',
    87: 'Bjelorusija',
    88: 'Sjeverna Irska',
    89: 'Jamajka',
    90: 'Kenija',
    91: 'Panama',
    92: 'Makedonija',
    93: 'Kuba',
    94: 'Albanija',
    95: 'Honduras',
    96: 'Salvador',
    97: 'Malta',
    98: 'Kirgistan',
    99: 'Moldavija',
    100: 'Gruzija',
    101: 'Andora',
    102: 'Gvatemala',
    103: 'Jordan',
    104: 'Jermenija',
    105: 'Trinidad i Tobago',
    121: 'Nikaragva',
    122: 'Kazahstan',
    123: 'Surinam',
    125: 'Lihtenštajn',
    126: 'Algežir',
    127: 'Mongolija',
    128: 'Lebanon',
    129: 'Bahrein',
    130: 'Barbados',
    131: 'Zelenootorska Ostrva',
    132: 'Obala Slonovače',
    133: 'Azerbejdžan',
    134: 'Kuvajt',
    135: 'Irak',
    136: 'Crna Gora',
    137: 'Angola',
    138: 'Bangladeš',
    139: 'Jemen',
    140: 'Oman',
    142: 'Mozambik',
    143: 'Brunej',
    144: 'Gana',
    145: 'Kambodža',
    147: 'Benin',
    148: 'Siria',
    149: 'Katar',
    150: 'Tanzanija',
    153: 'Uganda',
    154: 'Maldivi',
    163: 'Uzbekistan',
    165: 'Kamerun',
    166: 'Palestina',
    175: 'Šri Lanka',
    177: 'Sao Tome i Prinsipe',
    178: 'Kurasao',
    179: 'Gvam',
    180: 'Komori',
    181: 'DR Kongo',
    182: 'Etiopija',
    183: 'Madagaskar',
    184: 'Sveti Vincent i Grenadine',
    185: 'Bocvana',
    186: 'Belize',
    187: 'Zambija',
    188: 'Haiti',
    189: 'Mjanmar',
    190: 'Portoriko',
    191: 'San-Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Grenada',
    196: 'Tahiti',
    197: 'Gvajana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Ovo polje može sadržati samo alha-numeričke karaktere.',
      alpha_dash: 'Ovo polje može sadržati samo alha-numeričke karaktere, donje ili srednje crte.',
      confirmed: 'Potvrda se ne podudara.',
      email: 'Ovo polje mora biti validna email adresa.',
      max: 'Polje sadrži previše karaktera.',
      min: 'Polje sadrži premalo karaktera.',
      required: 'Neophodno polje.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Naslov je obavezan.',
        body: 'Sadžaj je obavezan.',
        username: 'Korisničko ime je obavezno.',
        password: 'Lozinka je obavezna.',
        locale: 'Jezik je obavezan.',
        name: 'Ime je obavezno.',
        token: 'Token je obavezan.'
      },
      integer: {
        native_country_id: 'ID države mora biti broj.',
        htms_min: 'Vrijednost HTMS minimuma mora biti broj.',
        htms_max: 'Vrijednost HTMS maksimuma mora biti broj.',
        htms28_min: 'Vrijednost HTMS28 minimuma mora biti broj.',
        htms28_max: 'Vrijednost HTMS28 maksimuma mora biti broj.',
        tsi_min: 'Vrijednost TSI minimuma mora biti broj.',
        tsi_max: 'Vrijednost TSI maksimuma mora biti broj.'
      },
      numeric: {
        age_min: 'Minimum godina mora biti broj.',
        age_max: 'Maksimum godina mora biti broj.',
        salary_min: 'Minimum plate mora biti broj.',
        salary_max: 'Maksimum plate mora biti broj.',
        price_min: 'Minimalna cijena mora biti broj.',
        price_max: 'Maksimalna cijena mora biti broj.'
      },
      required: {
        subject: 'Naslov je obavezan.',
        body: 'Sadžaj je obavezan.',
        username: 'Korisničko ime je obavezno.',
        password: 'Lozinka je obavezna.',
        current_password: 'Trenutna lozinka je obavezna.',
        new_password: 'Nova lozinka je obavezna.',
        email: 'Email adresa je obavezna.',
        token: 'Token je obavezan.',
        endpoint: '\'endpoint\' parametar je neophodan.',
        url: '\'URL\' parametar je neophodan.',
        oauth_token: '\'oauth_token\' parametar je neophodan.',
        oauth_verifier: '\'oauth_verifier\' parametar je neophodan.'
      },
      max: {
        age_min: 'Minimum godina mora biti manji od 100.',
        age_max: 'Maksimum godina mora biti manji od 100.',
        htms_min: 'Minimum HTMS vrijednosti mora biti manji od 12.000.',
        htms_max: 'Maksimum HTMS vrijednosti mora biti manji od 12.000.',
        htms28_min: 'Minimum HTMS28 vrijednosti mora biti manji od 12.000.',
        htms28_max: 'Maksimum HTMS28 vrijednosti mora biti manji od 12.000.',
        tsi_min: 'Minimum TSI vrijednosti mora biti manji od 16.000.000.',
        tsi_max: 'Maksimum TSI vrijednosti mora biti manji od 16.000.000.',
        subject: 'Naslov sadrži previše karaktera.',
        username: 'Korisničko ime sadrži previše karaktera.',
        email: 'Email adresa sadrži previše karaktera.',
        locale: 'Jezik sadrži previše karaktera.',
        name: 'Ime sadrži previše karaktera.',
        token: 'Token sadrži previše karaktera.',
        NT_player: 'Vrijednost igrača Nacionalnog Tima mora biti maksimum 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Lozinka sadrži premalo karaktera.',
        age_min: 'Minimum godina mora biti veći od 17.',
        age_max: 'Maksimum godina mora biti veći od 17.',
        native_country_id: 'ID države mora biti veći od 0.',
        htms_min: 'Minimum HTMS vrijednosti mora biti pozitivan broj.',
        htms_max: 'Maksimum HTMS vrijednosti mora biti pozitivan broj.',
        htms28_min: 'Minimum HTMS28 vrijednosti mora biti veći od -12.000.',
        htms28_max: 'Maksimum HTMS28 vrijednosti mora biti veći od -12.000.',
        tsi_min: 'Minimum TSI vrijednosti mora biti pozitivan broj.',
        tsi_max: 'Maksimum TSI vrijednosti mora biti pozitivan broj.',
        salary_min: 'Minimum plate mora biti pozitivan broj.',
        salary_max: 'Maksimum plate mora biti pozitivan broj.',
        price_min: 'Minimalna cijena mora biti pozitivan broj.',
        price_max: 'Maksimalna cijena mora biti pozitivan broj.',
        NT_player: 'Vrijednost igrača Nacionalnog Tima mora biti maksimum 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Polje za email adresu mora sadržati validnu email adresu.'
      },
      url: {
        url: '\'URL\' parametar mora biti validna URL adresa.'
      },
      in: {
        endpoint: '\'endpoint\' parametar mora biti validan.',
        form_min: 'Minimalna forma mora biti između 1 (\'@:player.levels.skill[1]\') i 8 (\'@:player.levels.skill[8]\').',
        form_max: 'Maksimalna forma mora biti između 1 (\'@:player.levels.skill[1]\') i 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'Ličnost mora biti između 0 (\'@:player.levels.agreeability[0]\') i 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'Ličnost mora biti između 0 (\'@:player.levels.agreeability[0]\') i 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'Agresivnost mora biti između 0 (\'@:player.levels.aggressiveness[0]\') i 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'Agresivnost mora biti između 0 (\'@:player.levels.aggressiveness[0]\') i 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'Poštenje mora biti između 0 (\'@:player.levels.honesty[0]\') i 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'Poštenje mora biti između 0 (\'@:player.levels.honesty[0]\') i 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'Iskustvo mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'Iskustvo mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Vođstvo mora biti između 1 (\'@:player.levels.skill[1]\') i 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Vođstvo mora biti između 1 (\'@:player.levels.skill[1]\') i 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'Kondicija mora biti između 0 (\'@:player.levels.skill[0]\') i 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'Kondicija mora biti između 0 (\'@:player.levels.skill[0]\') i 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Vještina na golu mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Vještina na golu mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Vještina plejmejking mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Vještina plejmejking mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Vještina napad mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Vještina napad mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Vještina dodavanje mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Vještina dodavanje mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Vještina krilo mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Vještina krilo mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Vještina odbrana mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Vještina odbrana mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Vještina prekidi mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Vještina prekidi mora biti između 0 (\'@:player.levels.skill[0]\') i 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'Ovaj korisnik više ne postoji.'
      },
      boolean: {
        promoted: 'Parametar \'promoted\' mora biti da ili ne.',
        bookmarked: 'Parametar \'bookmarked\' mora biti da ili ne.',
        ignored: 'Parametar \'ignored\' mora biti da ili ne.',
        notifications_email: 'Parametar \'notifications_email\' mora biti da ili ne.',
        notifications_desktop: 'Parametar \'notifications_desktop\' mora biti da ili ne.'
      },
      array: {
        specialties: 'Specijalnosti moraju sadržati niz vrijednosti.'
      }
    },
    unknown: {
      general: 'Desila se neočekivana greška. Predlažemo da osvježite stranicu.'
    },
    network: {
      general: 'Imate problema sa konekcijom na Internet.',
      maintenance: 'Održavanje sistema je u toku. Pokušajte za nekoliko.'
    },
    denied: {
      access: 'Nedozvoljen pristup.'
    },
    expired: {
      token: 'Token za autentikaciju je istekao.',
      session: 'Koristik nije potvrđen.'
    },
    failed: {
      registration: 'Nije moguće završiti registraciju.',
      user_details: 'Korisničke detalje nije moguće povratiti',
      player_details: 'Detalje o igraču nije moguće povratiti.',
      filter_fetch: 'Nemoguće je pogledati nove igrače',
      password_update: 'Lozinka se nije ažurirala.',
      chpp_connection: 'Nemoguće je uspostaviti CHPP konekciju.',
      chpp_authorization: 'Nemoguće je pokrenuti CHPP autorizaciju.'
    },
    invalid: {
      credentials: 'Netačni kredencijali.',
      token: 'Token za autentikaciju je netačan.',
      request: 'Nepravilan zahtjev.'
    },
    limit: {
      filters: 'Premašili ste broj dozvoljenih filtera koje možete napraviti.'
    },
    missing: {
      token: 'Nedostaje token za autentikaciju.'
    },
    conflict: {
      username: 'Korisničko ime se već koristi.',
      username_password: 'Korisničko ime i/ili email se već koriste.'
    }
  }
}
